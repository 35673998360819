.particle-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none; /* Prevents blocking UI */
  z-index: -1; /* Keep it behind everything */
  overflow: hidden;
}

.particle {
  position: absolute;
  width: 6px; /* Adjust size */
  height: 6px;
  background: radial-gradient(circle, rgba(150, 0, 255, 0.9) 20%, rgba(0, 0, 0, 0)); /* Mystical glow */
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(150, 0, 255, 0.8); /* Add neon glow */
  opacity: 0;
  animation: floatParticles 5s linear infinite;
}

@keyframes floatParticles {
  0% {
    opacity: 0;
    transform: scale(0.5) translateY(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.3) translateY(-50px);
  }
  100% {
    opacity: 0;
    transform: scale(0.8) translateY(-100px);
  }
}
