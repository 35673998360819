/* Overflow-hidden wrapper */
.overflow-hidden {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background-color: black; /* Prevent gaps */
}

/* Spinning Vortex Background */
.vortex-background {
  position: fixed;
  top: -50%; /* Extend further to ensure full coverage */
  left: -50%;
  width: 200vw; /* Significantly increase size */
  height: 200vh;
  background-image: url('./assets/vortex.png');
  background-size: cover; /* Ensures the image stretches fully */
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
  animation: spin 60s linear infinite;
  z-index: -1;
  filter: blur(4px);
  transform-origin: center center;
}

@keyframes spin {
  from {
    transform: rotate(0deg) scale(1.5); /* Scale up while rotating */
  }
  to {
    transform: rotate(360deg) scale(1.5); /* Keeps it large */
  }
}

/* Light Swirl Effect */
.light-swirl {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150vw;
  height: 150vh;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 30%, rgba(0,0,0,0) 70%);
  transform: translate(-50%, -50%);
  animation: swirl 20s linear infinite;
  mix-blend-mode: overlay;
}

@keyframes swirl {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Particle Accretion Disk */
.particle-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150vw;
  height: 150vh;
  overflow: hidden;
  pointer-events: none;
}

.particle {
  position: absolute;
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
  opacity: 0.8;
  animation: orbit 5s linear infinite;
}

@keyframes orbit {
  0% { transform: translateX(0) translateY(0) scale(1); opacity: 1; }
  100% { transform: translateX(150px) translateY(150px) scale(0); opacity: 0; }
}

/* Pulsing Glow Behind the Input Field */
.input-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Error Message Styling */
.error-message {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff4444;
  text-shadow: 0px 0px 10px red, 0px 0px 20px darkred;
  text-align: center;
  padding: 15px;
}

/* Footer */
.footer {
  position: fixed; /* instead of fixed */
  margin-top: 20px;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.3);
  z-index: 10;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .footer {
    position: relative;
    margin-top: 20px;
  }
}



/* Volume Control Styling */
.volume-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: 14%;
}

.volume-label {
  font-size: 16px;
  color: white;
  margin-bottom: 5px;
}

.volume-slider {
  width: 100%;
  appearance: none;
  height: 8px;
  background: linear-gradient(to right, #00ffff, #ff00ff);
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: background 0.3s;
}

.volume-slider:hover {
  background: linear-gradient(to right, #00ffff, #ff9900);
}

.start-audio-button {
  background: #ff00ff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  margin: 15px auto;
  display: block;
  transition: background 0.3s ease;
}

.start-audio-button:hover {
  background: #ff9900;
}

.input-glow {
  position: absolute;
  top: -55%;
  left: -15%;
  width: 370px;
  height: 400px;
  background: rgba(255, 255, 0, 0.2);
  border-radius: 10px;
  filter: blur(30px);
  animation: glowPulse 2s infinite alternate;
  z-index: -1;
}

@keyframes glowPulse {
  0% { opacity: 0.4; transform: scale(1); }
  100% { opacity: 1; transform: scale(1.1); }
}

/* Pulsating Title */
/* .pulsating-title {
  font-size: 2.5rem;
  color: white;
  text-align: center;
  text-shadow: 0px 0px 10px purple, 0px 0px 20px violet;
  animation: pulseGlow 2s infinite alternate;
} */

@keyframes pulseGlow {
  0% { text-shadow: 0px 0px 10px purple, 0px 0px 20px violet; }
  100% { text-shadow: 0px 0px 20px purple, 0px 0px 30px violet; }
}

/* Form Wrapper (for glow effect) */
.form-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Form Container */
.form-container {
  position: relative;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

/* Glowing Input Fields */
.form-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
  transition: box-shadow 0.3s ease-in-out;
}

.form-input:focus {
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.7);
}

/* Glowing Button */
.glow-button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  background: linear-gradient(45deg, #ff00ff, #ff9900);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(255, 0, 255, 0.5);
  transition: all 0.3s ease-in-out;
}

.glow-button:hover {
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.8);
  transform: scale(1.05);
}

/* Floating Form Label */
.form-label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  margin-bottom: 5px;
}




/* Slow down the animation
.vortex-background {
  animation: spin 120s linear infinite; /* Double the duration for smoother rotation
}*/

/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: black;
  overflow: hidden;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Darker Background to Enhance Text Visibility */
/* Results Box - More Transparency */
.results-container {
  position: relative;
  background: rgba(0, 0, 0, 0.75); /* Increase transparency */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px); /* Slightly reduce blur */
  text-align: center;
}


/* Reset Button - Neon Effect */
.reset-button {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(45deg, #ff0000, #ff4500);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(255, 69, 0, 0.7);
  transition: all 0.3s ease-in-out;
}

.reset-button:hover {
  box-shadow: 0 0 20px rgba(255, 69, 0, 1);
  transform: scale(1.05);
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

/* Pulsating Purple Glow for the Title */
.pulsating-title {
  font-size: 48px;
  font-weight: bold;
  color: white;
  text-align: center;
  text-shadow: 0 0 10px #8a2be2, 0 0 20px #8a2be2, 0 0 40px #8a2be2; /* Initial glow */
  animation: pulse 2s infinite alternate;
}

@keyframes pulse {
  0% {
    text-shadow: 0 0 10px #8a2be2, 0 0 20px #8a2be2, 0 0 40px #8a2be2; /* Strong glow */
  }
  100% {
    text-shadow: 0 0 5px #8a2be2, 0 0 10px #8a2be2, 0 0 20px #8a2be2; /* Softer glow */
  }
}

.form-container {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}

/* Style for Numerology Results */
/* Results Title - More Visibility */
.results-title {
  font-size: 2rem;
  color: #ffffff; /* Ensures it's bright */
  text-align: center;
  font-weight: bold;
  text-shadow:
    0px 0px 15px cyan, /* Outer glow */
    0px 0px 30px blue,  /* Stronger neon effect */
    0px 0px 45px rgba(0, 255, 255, 0.8); /* Extended glow */
  z-index: 10; /* Ensure it's above the glow */
  position: relative;
  padding: 10px;
}


input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

/* Separate Styling for Date Picker */
input[type="date"] {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgb(106, 54, 228); /* Set to white for visibility */
  color: rgb(255, 255, 255); /* Black text for better contrast */
}

/* Ensure Calendar Dropdown (Picker Icon) is Visible */
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(0); /* Ensures default light theme */
}


button {
  padding: 0.75rem 1.5rem;
  background-color: #8a2be2; /* Violet */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #6a0dad; /* Darker violet */
}

/* Ensures Life Path & Expression Numbers Are Visible */
.results-text span {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00ffff; /* Neon Cyan */
  text-shadow:
    0px 0px 10px #00ffff,
    0px 0px 20px rgba(0, 255, 255, 0.8);
  z-index: 10;
  position: relative;
}

/* Ensures Descriptions Are Readable */
.results-text {
  font-size: 1.2rem;
  color: #ffffff; /* Brighter white */
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8);
  transition: text-shadow 0.3s ease-in-out;
}

/* Glowing Hover Effect for Numbers */
.results-text span:hover {
  text-shadow:
    0px 0px 15px #00ffff,
    0px 0px 30px rgba(0, 255, 255, 1);
  transform: scale(1.1);
}

.personal-reading-form {
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
}

.personal-reading-form h3 {
  color: #00ffff; /* Neon Cyan */
  text-shadow:
    0px 0px 10px #00ffff,
    0px 0px 20px rgba(0, 255, 255, 0.8);
  text-align: center;
}

.personal-reading-form input,
.personal-reading-form textarea {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #555;
  color: white;
}

.personal-reading-form button {
  background: purple;
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
  cursor: pointer;
}

.personal-reading-form button:hover {
  background: darkviolet;
}

.confirmation {
  color: green;
  font-weight: bold;
  text-align: center;
}
